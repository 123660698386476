import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Valeria from '../images/avatar/valeria.jpg';
import Ksenia from '../images/avatar/ksenia.jpg';
import Jalkahoitaja from '../images/avatar/jh-hoitaja.jpg';
import logo from '../images/logo.svg';
import kosmetologi from '../images/bg/kosmetologi3.jpg';

// markup
const IndexPage = () => {
    return (
        <Layout>
            <>
                <section className="hero">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>
                                    Salon Looking <span>Good</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="herobg heroimage1"></div>
                </section>
                <section className="ads mb-5">
                    <div className="container">
                        <div className="row d-flex justify-content-around">
                            <div className="col-12 mt-5">
                                <h3 className='text-center'>Uutta!</h3>
                            </div>
                            <div className="col-md-4 col-12">
                                <article className="card">
                                    <img
                                        src={logo}
                                        alt="osoite"
                                        className="card-img-top"
                                    />
                                    <div className="card-body">
                                        <p>
                                            Olemme muuttaneet, uusi paikkamme on
                                            Ititksen kauppakeskuksessa Eastonin
                                            puolella Eurokangasta vastapäätä.
                                        </p>
                                        <strong>
                                            Katso uusi sijaintimme tästä
                                        </strong>
                                        <address>
                                            <a href="https://maps.app.goo.gl/1Qezpw59r3eUBQja7">
                                                tästä
                                            </a>
                                            .
                                        </address>
                                    </div>
                                </article>
                            </div>
                            <div className="col-md-4 col-12">
                                <article className="card">
                                    <img
                                        src={kosmetologi}
                                        alt="osoite"
                                        className="card-img-top"
                                    />
                                    <div className="card-body">
                                        <p>
                                            Pian meillä aloittaa 10-vuoden
                                            kokemuksen omaava ammattitaitoinen
                                            kosmetologi.
                                        </p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="intro py-3" data-aos="fade-up">
                    <div className="container m-2">
                        <div className="row justify-content-md-center">
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-4 center">
                                        <div className="title-box">
                                            <h2>Parturi-kampaamo</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="about-info">
                                            <h2 className="mb-30">
                                                "Salon looking Good on koko
                                                perheen parturi-kampaamo."
                                            </h2>
                                            <p className="p-intro">
                                                Tarjoamme miesten ja naisten
                                                hiusten leikkaus- ja
                                                kampaamopalveluita{' '}
                                                <strong>laadukkaasti</strong> ja{' '}
                                                <strong>edullisesti</strong>.
                                            </p>
                                            <p className="mb-30">
                                                <em>
                                                    Salon Looking Good sijaitsee
                                                    hyvien kulkuyhteyksien
                                                    varressa Itiksen
                                                    kauppakeskuksessa
                                                    Hansasillan alapuolella.
                                                </em>
                                            </p>
                                            <p className="p-intro p-intro-small">
                                                Laadukkaat ja kauniit kampaukset
                                                yli kymmenen vuoden
                                                ammattitaidolla ja hyvällä
                                                meiningillä henkilökohtaisesti
                                                ja aina asiakkaan toiveet
                                                huomioon ottaen. Liikkeessämme
                                                toimii ammattitaitoinen{' '}
                                                <Link to="/jalkahoito">
                                                    jalkahoitaja
                                                </Link>
                                                . Meille voit tulla myös ilman
                                                ajanvarausta. Tervetuloa!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services mb-50" data-aos="fade-up">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-10" data-aos="fade-up">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Link to="/parturi-kampaamo">
                                            <div data-aos="fade-up">
                                                <h4 className="subtitle">
                                                    <i className="fas fa-cut"></i>{' '}
                                                    Parturi
                                                </h4>
                                                <p className="services-text">
                                                    Hiusten leikkaus koneella ja
                                                    normaali.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4">
                                        <Link to="/parturi-kampaamo">
                                            <div data-aos="fade-up">
                                                <h4 className="subtitle">
                                                    <i className="fas fa-cut"></i>{' '}
                                                    Kampaamo
                                                </h4>
                                                <p className="services-text">
                                                    Hiusten leikkaus, värjäys,
                                                    raidat ja permanentti.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4">
                                        <Link to="/jalkahoito">
                                            <div data-aos="fade-up">
                                                <h4 className="subtitle">
                                                    <i className="fas fa-hand-lizard"></i>{' '}
                                                    Jalkahoito
                                                </h4>
                                                <p className="services-text">
                                                    Jalkahoitoa ammattitaidolla
                                                    ja edullisesti kaikille.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    className="team section-padding m-3"
                    data-aos="fade-up"
                >
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-10">
                                <ul className="row">
                                    <li className="col-md-4" data-aos="fade-up">
                                        <img
                                            className="img img-responsive"
                                            alt="Parturi-kampaaja - Ksenia"
                                            src={Ksenia}
                                        />
                                        <h5 className="mt-10 ls2 text-center">
                                            Ksenia <br /> Parturi-kampaaja
                                        </h5>
                                    </li>
                                    <li className="col-md-4" data-aos="fade-up">
                                        <img
                                            className="img img-responsive"
                                            alt="Parturi-kampaaja, Valeria"
                                            src={Valeria}
                                        />
                                        <h5 className="mt-10 ls2 text-center">
                                            Valeria <br /> Parturi-kampaaja
                                        </h5>
                                    </li>
                                    <li className="col-md-4" data-aos="fade-up">
                                        <img
                                            className="img img-responsive"
                                            alt="Jalkahoitaja, Inna"
                                            src={Jalkahoitaja}
                                        />
                                        <h5 className="mt-10 ls2 text-center">
                                            Inna <br /> Jalkahoitaja
                                        </h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    );
};

export default IndexPage;
